<script>
import Layout from "../../layouts/main"
import IdentifierNewItemForm from '@/components/drawers/product/IdentifierNewItemForm.vue'
import { common } from '@/mixins/common'
import { GetApiActionWithAuthorization, GetApiActionWithAuthorizationOnly } from "../../../helpers/apiActions";
import ManageBin from '@/components/drawers/product/manageBin.vue'
import ViewBinItemsList from '@/components/drawers/product/viewBinItemsList.vue'


    export default {
        page: {
            title: "New Product"
        },

        components: {
            Layout,
            IdentifierNewItemForm,
            ManageBin,
            ViewBinItemsList
        },

        data() {
            return {
                title: "New Product",
                allOwners: [],
                allStores: [],
                systemSetting: {},
                binNumber: '',
                displayManageBinPopup: false,
                displayBinItemPopup: false,
                nextDrawerLabel: '',
                formats: []
            }
        },

        mixins: [
            common
        ],
        mounted() {
            const _vm = this
            _vm.binNumber = _vm.$route.query.bin || ''
            _vm.loadPageData()
            _vm.loadAllOwners()
            _vm.loadAllStores()
            _vm.loadAllFormats()
        },
        methods: {
            loadAllFormats () {
                let _vm = this
                return GetApiActionWithAuthorization("admin/product-modes/get-page-data").then(res => {
                    _vm.formats = res.data
                });
            },
            loadAllStores () {
                let _vm = this
                return GetApiActionWithAuthorization("admin/products/get-all-stores").then(res => {
                    _vm.allStores = res.data
                });
            },
            loadAllOwners () {
                let _vm = this
                return GetApiActionWithAuthorization("admin/products/get-all-owners").then(res => {
                    _vm.allOwners = res.data
                });
            },
            loadPageData () {
                let _vm = this
                return GetApiActionWithAuthorizationOnly("admin/products/get-page-data").then(res => {
                    _vm.tabCount = res.count
                    _vm.systemSetting = res.setting
                    _vm.users = res.users
                });
            },
            handleStartBin () {
                let _vm = this
                _vm.displayManageBinPopup=true
                _vm.nextDrawerLabel = 'startIdentifiedBin'
            },
            viewList () {
                let _vm = this
                _vm.$root.$emit('display-bin-items-drawer', _vm.binNumber)
            },
        },
        computed: {
            hasBinNumber () {
                const _binNumber = parseInt(this.binNumber)
                return !isNaN(_binNumber) && _binNumber > 0
            }
        }
    }
</script>

<template>
    <div class="identifier-create-product-form">
        <el-drawer
            ref="manageBinDrawer"
            title="BIN Details"
            :visible.sync="displayManageBinPopup"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"
            :size="smallDrawerSize"
            :append-to-body="true"
            :destroy-on-close="true"
            custom-class="common-drawer demo-drawer">
            <ManageBin :next-drawer-label="nextDrawerLabel"/>
        </el-drawer>
        <el-drawer
            ref="binItemDrawer"
            :title="'Bin Items: B' + formatBinNumber(binNumber)"
            :visible.sync="displayBinItemPopup"
            :close-on-press-escape="false"
            :with-header="true"
            :wrapperClosable="false"
            size="70%"
            :append-to-body="true"
            :destroy-on-close="true"
            custom-class="common-drawer demo-drawer">
            <ViewBinItemsList :binId="binNumber"/>
        </el-drawer>
        <Layout>
            <div class="identifier-create-form pt-4">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <IdentifierNewItemForm
                            :owners="allOwners"
                            :applications="allStores"
                            :systemSetting="systemSetting"
                            :binNumber="hasBinNumber ? binNumber : ''"
                            :formats="formats"/>
                    </el-col>
                    <el-col
                        :span="8"
                        v-if="hasBinNumber">
                        <div class="">
                            <div class="">
                                <b style="font-size: 25px; color: #17b7a8;">BIN #{{ parseInt(binNumber) }}</b>
                            </div>
                            <div class="">
                                <button
                                    class="btn btn-danger"
                                    id="start-bin-btn"
                                    @click="handleStartBin()">
                                    <i class="fas fa-times-circle"></i> END BIN
                                </button>
                            </div>
                            <div style="margin-top: 15px;">
                                <button
                                    class="btn btn-info"
                                    id="list-bins-btn"
                                    @click="viewList()">
                                    <i class="fas fa-list-alt"></i> ITEMS LIST
                                </button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </Layout>
    </div>
</template>